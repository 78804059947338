<template>
  <section
    style="padding-left: 25px; margin-left: 20px; padding-right: 10px; margin-right: 10px;"
  >
    <v-card>
      <v-row>
        <v-col cols="12" sm="12" md="6">
          <v-row>
            <v-col cols="12" sm="12" md="3">
              <v-btn class=" primary ml-6 mr-2 mt-4 " @click="dialogAdd = true">
                Ajouter un Abonnement
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" sm="12" md="6">
          <v-card class="mb-6 ml-6 mt-6 mr-2">
            <v-card-title>Annuel</v-card-title>
            <v-data-table
              :search="search"
              :loading="isLoadingTables"
              loading-text="Loading..."
              :footer-props="{
                'items-per-page-options': [5, 10, 15, 30],
              }"
              :headers="headers"
              :items="listTSubWithEngagement"
              class="elevation-1"
              item-key="id"
            >
              <template v-slot:item.price="{ item }">
                {{ item.price }}€
              </template>
              <template v-slot:item.modifier="{ item }">
                <v-icon @click="toggleSolveStatus(item)">
                  {{ icons.mdiPencil }}
                </v-icon>
              </template>

              <template v-slot:item.supprimer="{ item }">
                <v-icon @click="suppressionAction(item)">
                  {{ icons.mdiDeleteOutline }}
                </v-icon>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
        <v-col cols="6" sm="12" md="6">
          <v-card class="mb-6 ml-6 mt-6 mr-2">
            <v-card-title>Mensuel</v-card-title>
            <v-data-table
              :search="search"
              :loading="isLoadingTables"
              loading-text="Loading..."
              :footer-props="{
                'items-per-page-options': [5, 10, 15, 30],
              }"
              :headers="headers"
              :items="listSubEngagement"
              class="elevation-1"
              item-key="id"
            >
              <template v-slot:item.price="{ item }">
                {{ item.price }}€ /mois
              </template>
              <template v-slot:item.modifier="{ item }">
                <v-icon @click="toggleSolveStatus(item)">
                  {{ icons.mdiPencil }}
                </v-icon>
              </template>
              <template v-slot:item.supprimer="{ item }">
                <v-icon @click="suppressionAction(item)">
                  {{ icons.mdiDeleteOutline }}
                </v-icon>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-dialog v-model="dialogEdit" persistent width="1024">
          <v-card>
            <v-card-title>
              <span class="text-h5">Modification Abonnement</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-form ref="form" lazy-validation>
                  <v-row>
                    <v-col cols="12" sm="12" md="6">
                      <v-text-field
                        v-model="subscribeEdit.name"
                        label="Nom d'abonnement"
                        outlined
                        persistent-placeholder
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <v-text-field
                        v-model="subscribeEdit.price"
                        label="Prix d'abonnement"
                        type="number"
                        oninput="if(this.value < 1) this.value = 1;"
                        required
                        persistent-placeholder
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12" md="16">
                      <v-text-field
                        v-model="subscribeEdit.priceId"
                        hide-details
                        outlined
                        label="Identifiant de paiment"
                      />
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error darken-1" text @click="dialogEdit = false">
                Annuler
              </v-btn>
              <v-btn color="info darken-1" text @click="validateEdit()">
                Enregistrer
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <v-row justify="center">
        <v-dialog v-model="dialogAdd" persistent width="1024">
          <v-card>
            <v-card-title>
              <span class="text-h5">Ajouter Abonnement</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-form ref="form" lazy-validation>
                  <v-row>
                    <v-col cols="12" sm="12" md="6">
                      <v-text-field
                        v-model="subscribe.name"
                        label="Nom d'abonnement"
                        outlined
                        persistent-placeholder
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <v-text-field
                        v-model="subscribe.price"
                        label="Prix d'abonnement"
                        type="number"
                        oninput="if(this.value < 1) this.value = 1;"
                        required
                        persistent-placeholder
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="subscribe.priceId"
                        hide-details
                        outlined
                        label="Identifiant de paiment"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12" md="16">
                      <v-select
                        v-model="subscribe.engagement"
                        clearable
                        label="Type d'abonement"
                        :items="subscribeType"
                        item-text="text"
                        item-value="value"
                        outlined
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error darken-1" text @click="Clear()">
                Annuler
              </v-btn>
              <v-btn
                color="info darken-1"
                :disabled="
                  subscribe.name.length <= 0 ||
                    subscribe.price < 0 ||
                    subscribe.priceId.length <= 0
                "
                text
                @click="addSubscribe()"
              >
                Enregistrer
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="600">
          <v-card>
            <v-card-title class="text-h6">
              Etes vous sur de vouloir supprimer l'engagement ?
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                color="error darken-1 error"
                text
                @click="dialogDelete = false"
              >
                Annuler
              </v-btn>

              <v-btn color="info darken-1 " text @click="deleteSub()">
                Confirmer
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-card>
    <v-card style="margin-top: 50px;">
      <v-card-title class="text-h5">
        Méthode de Paiement
      </v-card-title>
      <v-text-field
        style="margin: 20px;"
        label="Stripe Api Key"
        placeholder="Stripe Api Key"
        v-model="StripApiKey.url"
        outlined
      ></v-text-field>
      <v-row>
        <v-col cols="6" sm="6" md="6">
          <v-text-field
            style="margin: 20px;"
            label="Coupon Mensuel"
            placeholder="Coupon Mensuel"
            v-model="StripApiKey.couponMonthly"
            outlined
          ></v-text-field
        ></v-col>
        <v-col cols="6" sm="6" md="6">
          <v-text-field
            style="margin: 20px;"
            label="Coupon Annuel"
            placeholder="Coupon Annuel"
            v-model="StripApiKey.couponYearly"
            outlined
          ></v-text-field
        ></v-col>
      </v-row>
      <v-card-actions class="mt-2">
        <v-spacer></v-spacer>

        <v-btn class=" primary ml-6 mr-2 mt-4 " @click="dialogApiStrip = true">
          Sauvegarder
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="dialogApiStrip" max-width="600">
      <v-card>
        <v-card-title class="text-h6" style="text-align: center;">
          Êtes-vous certain de vouloir apporter des modifications à l'API ?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="error darken-1 error"
            text
            @click="dialogApiStrip = false"
          >
            Annuler
          </v-btn>

          <v-btn color="info darken-1 " text @click="updateStripApiKey()">
            Confirmer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import axios from "axios";
import Constant from "@/utils/constants";
import {
  mdiPlus,
  mdiDeleteOutline,
  mdiPencil,
  mdiDatabasePlusOutline,
  mdiLockOpen,
  mdiLock,
} from "@mdi/js";
export default {
  data() {
    return {
      subscribeType: [
        { text: "Engagement 12 Mois", value: "true" },
        { text: "Sans Engagement", value: "false" },
      ],
      dialogApiStrip: false,
      subscribe: {
        name: "",
        price: null,
        priceId: "",
        engagement: false,
      },
      icons: {
        mdiLockOpen,
        mdiLock,
        mdiPlus,
        mdiDeleteOutline,
        mdiPencil,
        mdiDatabasePlusOutline,
      },
      dialogAdd: false,
      dialogEdit: false,
      listSubEngagement: [],
      listTSubWithEngagement: [],
      dialogConfirmation: false,
      dialogDelete: false,
      search: "",
      isLoadingTables: false,
      listComplaint: [],
      Complaint: null,
      subscribeEdit: [],
      idSupprimmer: null,
      StripApiKey: {
        url: null,
        couponMonthly: null,
        couponYearly: null,
      },
      headers: [
        { text: "Abonnement", value: "name" },
        { text: "Prix", value: "price" },

        { text: "Modifier", value: "modifier", align: "center" },
        { text: "Supprimer", value: "supprimer", align: "center" },
      ],
    };
  },
  methods: {
    async validateEdit() {
      const response = await fetch(
        Constant.QORANI + "premium/edit/" + this.subscribeEdit.id,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(this.subscribeEdit),
        }
      );

      try {
        window.location.reload();
      } catch (error) {
        alert("Une erreur est survenue , veuillez réessayez plus tard, Merci.");
      }
    },
    async Clear() {
      this.dialogAdd = false;

      this.subscribe.name = "";
      this.subscribe.price = null;
      this.subscribe.priceId = "";
      this.subscribe.engagement = false;
    },
    async deleteSub() {
      const responseCategorie = await fetch(
        Constant.QORANI + `premium/delete/${this.idSupprimmer}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      try {
        window.location.reload();
      } catch (error) {
        alert(
          "Une erreur est survenue lors de la suppression du juz, veuillez réessayez plus tard, Merci."
        );
        this.dialogDelete = false;
      }
    },
    async suppressionAction(item) {
      this.idSupprimmer = item.id;
      this.dialogDelete = true;
    },
    async addSubscribe() {
      const response = await fetch(Constant.QORANI + "premium/add", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(this.subscribe),
      });

      try {
        window.location.reload();
      } catch (error) {
        alert(
          "Une erreur est survenue lors de l'ajout de la surat, veuillez réessayez plus tard, Merci."
        );
      }
    },

    async toggleSolveStatus(subscribetoedit) {
      this.dialogEdit = true;
      this.subscribeEdit = subscribetoedit;
    },

    async getAllSubscribe() {
      try {
        var listSub;
        const response = await axios.get(Constant.QORANI + "premium/getall");

        if (response.data) {
          listSub = response.data;

          for (let i = 0; i < listSub.length; i++) {
            if (listSub[i].engagement === false) {
              this.listSubEngagement.push(listSub[i]);
            } else if (listSub[i].engagement === true) {
              this.listTSubWithEngagement.push(listSub[i]);
            }
          }
        } else {
          console.error("Invalid data received from the API");
          return null;
        }
      } catch (error) {
        console.error("Error fetching complaints:", error);
        throw error;
      }
    },
    async getStripApiKey() {
      const response = await axios
        .get(Constant.QORANI + "checkout/getApiKey")
        .then((response) => {
          this.StripApiKey = response.data;
          console.log(this.StripApiKey);
        });
    },
    async updateStripApiKey() {
      if (this.StripApiKey.id) {
        console.log(this.StripApiKey);
        const response = await fetch(
          Constant.QORANI + "checkout/editApiKey/" + this.StripApiKey.id,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(this.StripApiKey),
          }
        );

        try {
          window.location.reload();
        } catch (error) {
          alert(
            "Une erreur est survenue, veuillez réessayer plus tard. Merci."
          );
        }
      } else {
        const response = await fetch(Constant.QORANI + "checkout/addApiKey/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(this.StripApiKey),
        });

        try {
          window.location.reload();
        } catch (error) {
          alert(
            "Une erreur est survenue, veuillez réessayer plus tard. Merci."
          );
        }
      }
    },
  },
  async created() {
    await this.getStripApiKey();
    this.isLoadingTables = true;
    await this.getAllSubscribe();
    this.isLoadingTables = false;
  },
};
</script>
